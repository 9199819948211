import React from "react";
import "./success.css"; // Import your regular CSS file

function Success() {
  return (
    <div className="pageContainer"> 
      <div className="rectangle">
        <div className="topBox">
          <div className="rectanglebar"/>
          <div className="h1TextBox">
            <h1 className="h1text">Thank you for choosing Academate.</h1>
          </div>
        </div>
        <div className="midBox">
              <h3 className= "h3text">Congrats on taking the first step towards perfecting your college application! 🎉 </h3>
        </div>
        <div className="upperMidBox">
              <h3 className="subtitletext">Our team will be in touch at the email provided shortly.</h3>
        </div>
        <div className="qBox">
                <p className= "q's?">Questions?</p>
                <a href="https://academate.org/#contact">Contact Us.</a>
        </div>
                <img src="img/aclogoinverse.jpeg" className="imglogo" alt="" />
        </div>
    </div>
  );
}

export default Success;