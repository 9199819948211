const productsArray= [
        {
            icon: "fa fa-comments-o",
            title: "Essay Review",
            text: "Receive line-by-line edits and intensive feedback on any of your college essays! Packages by school are available.",
            options: [
                {
                    id: "price_1NfBIbGD6xjHTmd1PN4AFcxf",
                    title: "Common App Essay",
                    price: 45.00
                },
                {
                    id: "price_1NfBNtGD6xjHTmd1YWsqi2Ko",
                    title: "Supplemental Essay Under 150 Words",
                    price: 15.00
                },
                {
                    id: "price_1NfBOSGD6xjHTmd1XjHM2vOa",
                    title: "Supplemental Essay 150-600 Words",
                    price: 25.00
                },
                {
                    id: "price_1NfBPIGD6xjHTmd1QcKdQklD",
                    title: "Supplemental Essay 600+ Words",
                    price: 30.00
                }
    
            ]
        },
        {
            icon: "fa fa-bullhorn",
            title: "Consultation Phone Calls",
            text: "Use this time with our consultants as you wish! Work on crafting your extracurricular profile and begin brainstorming essay topics!",
            options: [
                {
                    id: "price_1NfBLDGD6xjHTmd1EHHAo9Xv",
                    title: "Consultation Phone Calls (1 hour)",
                    price: 30.00
                }
            ]
        },
        {
            icon: "fa fa-group",
            title: "Full Application Review",
            text: "A truly comprehensive review, including the common app essay, activities list review, awards section, additional info section, and more! We also review non-common applications (MIT, UCs, UTs, etc). *Does not include supplemental essays*",
            options: [
                {
                    id: "price_1NddydGD6xjHTmd1yqJ3Qe9M",
                    title: "Full Application Review",
                    price: 85.00
                }
            ]
        },
        {
            icon: "fa fa-magic",
            title: "School Specific Packages",
            text: "Want to make sure your dream school esssays are perfect? Select a school below for a full, comprehensive review.",
            options: [
                {
                    id: "price_1NddzeGD6xjHTmd1QUBiV6P5",
                    title: "University of Michigan Essays",
                    price: 45.00
                },
                {
                    id: "price_1NfBPyGD6xjHTmd1pybyxJYw",
                    title: "University of Michigan - Ross Essays",
                    price: 45.00
                },
                {
                    id: "price_1NfBQLGD6xjHTmd1Qec3H733",
                    title: "Massachusetts Institute of Technology Essays",
                    price: 85.00
                },
                {
                    id: "price_1NfBQpGD6xjHTmd1c69oXRc8",
                    title: "Duke University Essays",
                    price: 65.00
                },
                {
                    id: "price_1NfBRpGD6xjHTmd18BFgO6XW",
                    title: "University of California Essays",
                    price: 90.00
                },
                {
                    id: "price_1NfBSHGD6xjHTmd1OyY1DJVg",
                    title: "Carnegie Mellon University Essays",
                    price: 65.00
                }
            ]
        }
    ]
    
    function getProductData(id) {
        let productData;
    
        for (let i = 0; i < productsArray.length; i++) { 
            productData = productsArray[i].options.find(product => product.id === id);
            if (productData !== undefined) {
                return productData;
            }
        }
    
        return productData;
    }
    
    export { productsArray, getProductData };
