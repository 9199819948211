import React from "react";
import "./cancel.css"; 

function Cancel (){
    return (
        <div className="PageContainerC">
            <div className="rectangleC">
                <div className="topboxC">
                    <img src="img/basket.png" className="imgcancelC" alt=""/>
                </div>
                <div className="midboxC">
                    <h1 className="h1C">Your order has been cancelled.</h1>
                </div>
                <div className="qBoxC">
                    <p className= "q's?">Something we did wrong?</p>
                    <a href="https://academate.org/#contact">Let us know.</a>
                </div>
                    <img src="img/aclogoinverse.jpeg" className="imglogoC" alt="" />
                </div>
            </div>
    )
}

export default Cancel;