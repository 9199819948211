import React, {useState, useContext} from "react";
import {Button, Container, Navbar, Modal} from 'react-bootstrap'
import CartProduct from "./CartProduct";
import { CartContext } from '../CartContext'
import "./new.css"

export const Navigation = (props) => {
  const [show, setShow] = useState(false);
  const cart = useContext(CartContext);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

  const checkout = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://example-backend-6jlv.onrender.com/checkout/', {
          method: "POST",
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({items: cart.items})
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setLoading(false);

      if (data.url) {
          window.location.assign(data.url); // Forwarding user to Stripe
      }
    } catch (error) {
      console.error('There was an error!', error);
      setLoading(false);
    }
  };

  return (
    <>
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img
              src="img/academatelogo.png"
              alt="logo" 
              className="navBar-logo"
            />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a href="#features" className="page-scroll">
                Pricing
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            <li>
              {/* <img src="img/cart-alt-regular-24.png" alt="cart" className="cartlogo" /> */}
              <Button onClick={handleShow}>Cart {productsCount} Items</Button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <Modal style={{background: "rgba(0,0,0,0.6)"}} show={show} onHide={handleClose} animation={false} className="special_modal">
      <Modal.Header style={{background: "white"}}>
        <Modal.Title style={{color: "black"}}>Shopping Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{background: "white", color: "black"}}> 
                    {productsCount > 0 ?
                        <>
                            <p>Items in your cart:</p>
                            {cart.items.map( (currentProduct, idx) => (
                                <CartProduct key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
                            ))}

                            <h1>Total: {cart.getTotalCost().toFixed(2)}</h1>

                            <Button variant="success" onClick={checkout}>
                                Purchase items!
                            </Button>
                            {loading ? (<h3>
                              Loading... Please Wait (up to 30 seconds)
                            </h3>) : (<p></p>)
                            }
                        </>
                    :
                        <h1>There are no items in your cart!</h1>
                    }
                </Modal.Body>

    </Modal>
</>
  );
};
