import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cancel from './pages/Cancel'
import Success from "./pages/Success";
import MainPage from "./MainPage";
import CartProvider from "./CartContext";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <CartProvider>
      <div>
        {/* <Navigation /> */}
        <BrowserRouter>
          <Routes>
            <Route index element={<MainPage/>}/>
            <Route path="success" element={<Success/>}/>
            <Route path="cancel" element={<Cancel/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </CartProvider>
  );
};

export default App;
