import React, { useState, useContext } from "react";
import {Container, Button, Row, Col, Form} from 'react-bootstrap';
import { productsArray, getProductData} from "../productsStore";
import { CartContext } from '../CartContext';


// function DropdownItemTagsExample() {
//   return (
//     <DropdownButton id="dropdown-item-button" title="Dropdown button">
//       <Dropdown.ItemText>Dropdown item text</Dropdown.ItemText>
//       <Dropdown.Item as="button">Action</Dropdown.Item>
//       <Dropdown.Item as="button">Another action</Dropdown.Item>
//       <Dropdown.Item as="button">Something else</Dropdown.Item>
//     </DropdownButton>
//   );
// }

function DropdownMenu(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [itemID, setItemID] = useState();
  const [selectedValue, setSelectedValue] = useState("Select...");
  const option = props.option;
  const cart = useContext(CartContext);

  console.log(cart.items)
  const productQuantity = cart.getProductQuantity(itemID);
  // console.log(cart.items);

  return (
    <div className="dropdown">
        <button
        className="dropdown-toggle"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen)
        }}
      >
        {selectedValue}
      </button>
        {isMenuOpen && (
          <ul className="dropdown-menu">
            
            {option ? option.map((product, idx) => (
                <li key={idx}>
                  {/* {product.title} ({product.price}) */}
                  <button onClick={() => {
                    setItemID(product.id)
                    setSelectedValue(getProductData(product.id).title)
                    setIsMenuOpen(!isMenuOpen)
                  }  
                }>{product.title} (${product.price})</button>
                </li>
              )) : "Loading..."}
          </ul>
        )}
         { productQuantity > 0 ?
                    <>
                        <Form as={Row}>
                            <Form.Label column="true" sm="6">In Cart: {productQuantity}</Form.Label>
                            <Col sm="6">
                                <Button sm="6" onClick={() => cart.addOneToCart(itemID)} className="mx-2">+</Button>
                                <Button sm="6" onClick={() => cart.removeOneFromCart(itemID)} className="mx-2">-</Button>
                            </Col>
                        </Form>
                        <Button variant="danger" onClick={() => cart.deleteFromCart(itemID)} className="my-2">Remove from cart</Button>
                    </>
                    :
        <Button onClick={() => {
          if (itemID){
            cart.addOneToCart(itemID)
          }
        }}>
          Add to Cart
        </Button>}
        {/* <p>
          {itemID} {selectedValue}
        </p> */}
    </div>
  );
}


export const Order = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const cart = useContext(CartContext);
  // const productQuantity = cart.getProductQuantity(product.id);

  return (
    <Container>
      <div id="features" className="text-center">
      <h2> </h2>
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2>Order Today!</h2>
          </div>
          <div className="row">
            {productsArray
              ? productsArray.map((d, i) => (
                  <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                    {" "}
                    <i className={d.icon}></i>
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                    {/* <DropdownButton id="dropdown-basic-button" title="Dropdown button">
                    {d.options ? d.options.map((product, idx) => (
                        <Dropdown.Item key={idx}>{product.title} ({product.price})</Dropdown.Item>
                      )) : "Loading..."}
                    </DropdownButton> */}
                    {/* <DropdownItemTagsExample></DropdownItemTagsExample> */}
                    <DropdownMenu option ={d.options}/>
                    {/* <div className="dropdown">
                      <button
                        className="dropdown-toggle"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                      >
                        Select...
                      </button>
                      {isMenuOpen && (<ul className="dropdown-menu">
                      {d.options ? d.options.map((product, idx) => (
                          <li key={idx}>{product.title} ({product.price})</li>
                        )) : "Loading..."}
                      </ul>)}
                      <Button>
                        Add to Cart
                      </Button>
                    </div> */}
                  </div>
                ))
              : "Loading... (initial request may take 10-15 seconds)"}
          </div>
          {/* <Row xs={1} md={3} className="g-4">
                {productsArray.map((product, idx) => (
                  <Col align="center" key={idx}>
                    <h1>{product.title}</h1>
                  </Col>
                ))}
          </Row> */}
        </div>
        <h2> </h2>
      </div>
    </Container>
  );
};
