import React, { useState, useEffect } from "react";
import { Header } from "./components/header";
import { Order } from "./components/order";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Navigation } from "./components/navigation";
import Store from "./pages/Store";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const MainPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
      <>
        <Navigation/>
        <Header data={landingPageData.Header} />
        <About data={landingPageData.About} />
        <Services data={landingPageData.Services} />
        <Order data={landingPageData.Features} /> 
        <Contact data={landingPageData.Contact} />
      </>
  );
};

export default MainPage;
