import {Container} from 'react-bootstrap';

function Store () {
    return (
        <Container>
            <h1>Store Page</h1>
        </Container>
    )
}

export default Store;